import { now } from '@/@core/utils/filter'
import {
  deleteSalesReport,
  downloadSalesReport,
  fetchCancelRefundItems,
  fetchSalesItems,
  fetchSalesReport,
  fetchSalesReports,
  fetchSalesSummary,
  storeSalesReport,
} from '@/api/reporting'
import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import chStore from '@/modules/channel/store'
import { computed, ref, watch } from '@vue/composition-api'

const cached = []
export default function useChannelSalesReports() {
  const salesSummary = ref([])
  const extraReportOptions = [
    {
      title: 'Last Generated Report',
      value: {
        latest: 1,
        take: 1,
      },
    },
    {
      title: 'All Report',
      value: {},
    },
  ]
  const extraReportFilter = ref(extraReportOptions[0].value)

  const salesSummaryColumns = [
    { text: 'CHANNEL', value: 'channel', sortable: false },
    { text: 'CUSTOMER ID', value: 'customer_id', sortable: false },
    { text: 'RECEIPT NUMBER', value: 'receipt_number', sortable: false },
    { text: 'CUSTOM ID', value: 'custom_id', sortable: false },
    { text: 'STAFF ID', value: 'staff_id', sortable: false },
    { text: 'CASH', value: 'cash', sortable: false },
    { text: 'REWARD POINTS', value: 'reward_points', sortable: false },
    { text: 'FLEXI DOLLAR', value: 'flexi_dollar', sortable: false },
    { text: 'SHIPPING TOTAL', value: 'shipping_total', sortable: false },
    { text: 'SUBTOTAL', value: 'subtotal', sortable: false },
    { text: 'CHECKOUT TOTAL', value: 'checkout_total', sortable: false },
    { text: 'PAYMENT METHOD', value: 'payment_method', sortable: false },
    { text: 'CREATE DATETIME', value: 'created_at', sortable: false },
  ]

  const salesItems = ref([])
  const salesItemsColumns = [
    { text: 'ORDER NUMBER', value: 'order_number', sortable: false },
    { text: 'MERCHANT', value: 'merchant', sortable: false },
    { text: 'RECEIPT NUMBER', value: 'receipt_number', sortable: false },
    { text: 'CUSTOM ID', value: 'custom_id', sortable: false },
    { text: 'STAFF ID', value: 'staff_id', sortable: false },
    { text: 'CATEGORIES', value: 'categories', sortable: false },
    { text: 'ITEM', value: 'item', sortable: false },
    { text: 'PRODUCT TYPE', value: 'product_type', sortable: false },
    { text: 'UNIT PRICE', value: 'unit_price', sortable: false },
    { text: 'QUANTITY', value: 'quantity', sortable: false },
    { text: 'TOTAL', value: 'total', sortable: false },
  ]

  const refundItems = ref([])
  const refundItemsColumns = [
    { text: 'RECEIPT NUMBER', value: 'receipt_number', sortable: false },
    { text: 'CUSTOM ID', value: 'custom_id', sortable: false },
    { text: 'STAFF ID', value: 'staff_id', sortable: false },
    { text: 'CREATION DATE', value: 'creation_date', sortable: false },
    { text: 'CANCELLED DATE', value: 'cancelled_date', sortable: false },
    { text: 'STATUS', value: 'status', sortable: false },
    { text: 'REFUND TOTAL', value: 'refund_total', sortable: false },
    { text: 'REFUND CREDITS TOTAL', value: 'refund_credits_total', sortable: false },
    { text: 'REFUND_CREATE_DATE', value: 'refund_create_date', sortable: false },
    { text: 'REASON', value: 'reason', sortable: false },
  ]

  const salesReports = ref([])
  const salesReportsTotal = ref(0)
  const salesReportsColumns = [
    { text: 'STATUS', value: 'status', sortable: false },
    { text: 'FROM', value: 'report_from_date', sortable: false },
    { text: 'TO', value: 'report_to_date', sortable: false },
    { text: 'Credits Total', value: 'credits_total' },
    { text: 'Order count', value: 'order_count' },
    { text: 'Item count', value: 'sales_count' },
    { text: 'Items worth', value: 'subtotal' },
    { text: 'Cash Total', value: 'total' },
    { text: 'Refund/Cancel count', value: 'refund_count' },
    { text: 'Refund Cash Total', value: 'refund_total' },
    { text: 'Refund Credits Total', value: 'refund_credits_total' },
    { text: 'CHANNEL', value: 'channel', sortable: false },
    { text: 'ACTIONS', value: 'actions', sortable: false },
  ]

  const loading = ref(false)

  const options = ref(
    cached[0] || {
      sortBy: ['created_at'],
      sortDesc: [true],
      itemsPerPage: 10,
      page: 1,
    },
  )
  const form = ref(
    cached[1] || {
      created_at_from: now().subtract(6, 'day').startOf('day').format('YYYY-MM-DD'),
      created_at_to: now().subtract(1, 'day').endOf('day').format('YYYY-MM-DD'),
    },
  )

  const channelId = computed(() => chStore.state.channel?.id || undefined)

  const baseFilter = computed(() => ({
    channel_id: channelId.value,
    created_at_from: now(form.value.created_at_from).startOf('day').toString(),
    created_at_to: now(form.value.created_at_to).endOf('day').toString(),
  }))

  const loadSalesSummary = () =>
    fetchSalesSummary({ ...baseFilter.value }).then(({ data }) => {
      salesSummary.value = data.data.items
    })

  const loadSalesItems = () =>
    fetchSalesItems({ ...baseFilter.value }).then(({ data }) => {
      salesItems.value = data.data.items
    })

  const loadCancelRefundItems = () =>
    fetchCancelRefundItems({ ...baseFilter.value }).then(({ data }) => {
      refundItems.value = data.data.items
    })

  const loadSalesReports = () =>
    fetchSalesReports(
      useTableOptions(options.value, {
        channel_id: channelId.value,
        report_from_date: now(form.value.created_at_from).startOf('day').toString(),
        report_to_date: now(form.value.created_at_to).endOf('day').toString(),
        ...extraReportFilter.value,
      }),
    ).then(({ data }) => {
      salesReports.value = data.data.records
      salesReportsTotal.value = data.data.pagination.total
    })

  const reloadAll = async () => {
    loading.value = true
    options.value.page = 1
    try {
      // await loadSalesSummary()
      // await loadSalesItems()
      // await loadCancelRefundItems()
      await loadSalesReports()
    } catch (err) {
      useNotifyErrors(err)
    }
    loading.value = false
  }

  const cachable = [options, form, channelId]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, async () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    // loading.value = true
    // await loadSalesReports()
    // loading.value = false
    cache()
  })

  const attemptDownloadReport = async reportable => {
    const {
      data: { data: newReportable },
    } = await fetchSalesReport(reportable.id)
    const { report } = newReportable

    if (report && report.status === 'completed') {
      // downloadSalesReport(reportable.id) // popup blocked =/
      await loadSalesReports()
      useNotifySuccess({
        content: `Your sales report (${report.report_from_date} - ${report.report_to_date}) is ready. You may download it now. And make browser "allow popup" for "auto downloading" in the future.`,
        timeout: 10000,
      })
      downloadSalesReport(reportable.id)
    } else {
      setTimeout(() => attemptDownloadReport(reportable), 5000)
    }
  }

  const generateSalesReport = async () => {
    loading.value = true
    const {
      data: { data: reportable },
    } = await storeSalesReport({ ...baseFilter.value })

    // console.log('data report', reportable)
    await loadSalesReports()

    attemptDownloadReport(reportable)
    loading.value = false
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    extraReportOptions,
    extraReportFilter,
    salesSummary,
    salesSummaryColumns,

    salesItems,
    salesItemsColumns,

    refundItems,
    refundItemsColumns,

    salesReports,
    salesReportsTotal,
    salesReportsColumns,
    downloadSalesReport,
    loadSalesReports,
    deleteSalesReport,

    loading,
    form,
    options,
    reloadAll,
    generateSalesReport,
  }
}
